window.addEventListener("load", function () {
    const items = document.querySelectorAll('.question-box');
    const up = 'fa-chevron-up';
    const down = 'fa-chevron-down';

    items.forEach(question => {
        const chevron = question.querySelector('.faq-chevron');

        question.querySelector('.question').addEventListener('click', () => {

            items.forEach(otherQuestions => {
                const otherChevron = otherQuestions.querySelector('.faq-chevron');
                if (otherChevron.classList.contains(up)) {
                    otherChevron.classList.remove(up);
                    otherChevron.classList.add(down);
                }
            })

            if (question.classList.contains('open')) {
                items.forEach(i => i.classList.remove('open'));
                chevron.classList.remove(up);
                chevron.classList.add(down);
            } else {
                items.forEach(i => i.classList.remove('open'));
                chevron.classList.add(up);
                chevron.classList.remove(down);
                question.classList.add('open');
            }
        })
    });
})
