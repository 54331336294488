function closeNewNotice() {

    const noticeClose = jQuery('.noticeClose');
    const notice = jQuery('.notice');

    noticeClose.on('click', function () {
        notice.addClass('closed');
    })
}

function closeNotice() {
    if (document.body.contains(document.querySelector('.notice'))) {
    }

    const noticeClose = jQuery('.noticeClose');
    const notice = jQuery('.notice');

    noticeClose.on('click', function () {
        notice.addClass('closed');
    })
}

window.addEventListener("load", function () {
    closeNotice();
})

window.addEventListener('DOMNodeInserted', () => {
    closeNewNotice();
})