jQuery(function ($) {
    $(document.body).on('click', '.input-button', function () {
        const button = $(this);
        const input = button.parent().find('.quantity .qty') as JQuery<HTMLInputElement>;
        const value = parseInt(input.val() as string);

        let max = 999;

        if (input.attr('max')) {
            max = parseInt(input.attr('max'));
        }

        const min = parseInt(input.attr('min'));

        if ($(this).hasClass('input-plus')) {
            const newValue = Math.min(max, value + 1);
            input.val(newValue);
        }

        if ($(this).hasClass('input-minus')) {
            const newValue = Math.max(min, value - 1);
            input.val(newValue);
        }

        input.trigger('change');
    })
});