function aboutUsLayout() {
    const about_us = document.querySelector('.OverOnsComponent');
    const about_us_text = about_us.querySelector<HTMLElement>('.text');
    const about_us_img = about_us.querySelector<HTMLElement>('.text-thumbnail-wrapper');

    if (window.innerWidth < 768) {
        const about_us_h1 = about_us_text.querySelector<HTMLElement>('h1');
        const about_us_h2 = about_us_text.querySelector<HTMLElement>('h2');

        let firstHeader = about_us_h1
        if (!firstHeader) {
            firstHeader = about_us_h2
        }

        if (!about_us.contains(about_us.querySelector('#newHeader'))) {
            about_us_img.style.marginTop = '';

            const newHeader = document.createElement('h1');
            newHeader.setAttribute('id', 'newHeader');

            newHeader.innerHTML = firstHeader?.innerHTML;

            about_us.insertBefore(newHeader, about_us_text);

            firstHeader.remove();
        }
    } else if (window.innerWidth >= 768) {
        const about_us_h1 = about_us_text.querySelector<HTMLElement>('h1');
        const about_us_h2 = about_us_text.querySelector<HTMLElement>('h2');

        if (about_us_text.contains(about_us_h1) || about_us_text.contains(about_us_h2)) {
            about_us_img.style.marginTop = '6rem';
        }

        const aboutUsNewHeader = about_us.querySelector('#newHeader');
        if (about_us.contains(aboutUsNewHeader) && (!about_us.contains(about_us.querySelector('#reverted')))) {
            const revertedHeader = document.createElement("h1");
            revertedHeader.setAttribute('id', 'reverted');

            revertedHeader.innerHTML = aboutUsNewHeader.innerHTML;
            about_us_text.insertBefore(revertedHeader, about_us_text.firstChild);

            aboutUsNewHeader.remove();
        }
    }
}

window.addEventListener('load', () => {
    if (document.body.classList.contains('page-template-template-overons')) {
        aboutUsLayout();

        window.addEventListener('resize', () => {
            aboutUsLayout();

        })
    }
})