window.addEventListener("load", function () {
    const button = document.getElementById('phone-dropdown-button');
    const menu = document.querySelector('.phone-dropdown-menu');
    const menuLogo = menu.querySelector('.phone-menu-logo');

    const togglerContent = button.innerHTML;
    const buttonOpened = '<i class="fa-solid fa-xmark"></i>';

    menu.classList.remove('open');
    button.innerHTML = togglerContent;

    button.addEventListener('click', function (event) {
        menu.classList.toggle('open');

        if (menu.classList.contains('open')) {
            button.innerHTML = buttonOpened;
            document.body.style.overflowY = "hidden"
        } else {
            button.innerHTML = togglerContent;
            document.body.style.overflowY = "scroll"
        }
    })

    if (window.innerHeight < 800) {
        menuLogo?.classList.add('hidden');
    } else {
        menuLogo?.classList.remove('hidden');
    }
})

window.addEventListener('resize', () => {
    const menu = document.querySelector('.phone-dropdown-menu');
    const menuLogo = menu.querySelector('.phone-menu-logo');

    if (window.innerHeight < 800) {
        menuLogo?.classList.add('hidden');
    } else {
        menuLogo?.classList.remove('hidden');
    }
})
