// window.addEventListener('load', () => {
//     const tabs = document.querySelector('.tabs');
//     const tabTitles = tabs.querySelectorAll('.tab-titles > li');
//     const tabBlocks = tabs.querySelectorAll('.tab');
//
//     tabTitles.forEach(title => {
//         title.addEventListener('click', () => {
//
//             //title code
//
//             tabTitles.forEach(otherTitles => {
//                 otherTitles.classList.remove('open');
//             })
//
//             title.classList.add('open');
//
//             //blocks code
//
//             tabBlocks.forEach(block => {
//                 block.classList.remove('open');
//
//                 if ((block.id + '-title') == title.id) {
//                     block.classList.add('open');
//                 }
//             })
//         })
//     })
// })