window.addEventListener("load", function () {
    const wpText = document.querySelector('.WordDistrComponent');
    const wpTextTwo = document.querySelector('.OverOnsComponent');
    const wpButton = wpText?.querySelectorAll<HTMLElement>('a');
    const wpButtonTwo = wpTextTwo?.querySelectorAll<HTMLElement>('a');

    wpButton?.forEach(button => {
        button.parentElement.classList.add('wp-button-wrapper');
        button.classList.add('link-button');
    })

    wpButtonTwo?.forEach(buttonTwo => {
        buttonTwo.parentElement.classList.add('wp-button-wrapper');
        buttonTwo.classList.add('link-button');
    })
})