window.addEventListener('load', () => {
    const register = document.querySelector('.register-button');
    const registerForm = document.querySelector('.register-form');
    const login = document.querySelector('.login-button');
    const loginForm = document.querySelector('.login-form');

    if (document.body.contains(register)) {
        register.addEventListener('click', () => {
            registerForm.classList.add('open');
            loginForm.classList.remove('open');
        })
    }

    if (document.body.contains(login)) {
        login.addEventListener('click', () => {
            registerForm.classList.remove('open');
            loginForm.classList.add('open');
        })
    }
})